import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65f845f0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "BatchForm"
};
const _hoisted_2 = {
  style: {
    "margin": "16px"
  }
};
const _hoisted_3 = {
  style: {
    "margin": "16px"
  }
};
import Common_Router from "@/utils/Common/Common_Router";
import { computed, ref } from "vue";
import Apis_User from "@/apis/Apis_User";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import Common_Down from "@/utils/Common/Common_Down";
import Common_Str from "@/utils/Common/Common_Str";
export default {
  __name: 'BatchRegUser',
  setup(__props) {
    const FormInputOptions = [{
      name: '前缀',
      field: 'username'
    }, {
      name: '起始',
      field: 'startInt'
    }, {
      name: '长度',
      field: 'padding'
    }, {
      name: '数量',
      field: 'userNum'
    }, {
      name: '姓名',
      field: 'name'
    }, {
      name: '联系',
      field: 'contact'
    }];
    const postData = ref({
      username: '',
      startInt: '',
      padding: '',
      userNum: '',
      name: '',
      contact: ''
    });
    // const postData = ref({
    //     username:'test',startInt:'1000',padding:'5',
    //     userNum:'10',name:'测试Wap',contact:'10086',
    // });
    const onSubmit = () => {
      Apis_User.userBulkRegister(postData.value).then(res => {
        if (!Apis_User.checkResCode(res)) {
          return;
        }
        VantMsgLib.alertSuccess();
        let arr = res.userData || [];
        let line = '\r\n';
        let outputStr = ['USERNAME', 'PASSWORD'].toString() + line;
        for (let arrKey in arr) {
          let obj = arr[arrKey];
          let {
            username,
            password
          } = obj;
          outputStr += [username, password].toString() + line;
        }
        Common_Down.downLoad(outputStr, 'User.csv');
        VantMsgLib.notify(2, '已生成注册数据');
      });
    };
    const CalcUserNameExample = computed(() => {
      let form = postData.value;
      let bool = form.username && form.startInt && form.userNum && form.padding;
      if (!bool) {
        return '~';
      }
      let [padding, start, user] = [Number(form.padding), Number(form.startInt), form.username];
      let end = Number(form.userNum) + start;
      [start, end] = [Common_Str.fixZeroStart(start, padding), Common_Str.fixZeroStart(end, padding)];
      return `${user}${start} ~ ${user}${end}`;
    });
    return (_ctx, _cache) => {
      const _component_HeaderNavBar = _resolveComponent("HeaderNavBar");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_tag = _resolveComponent("van-tag");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_HeaderNavBar, {
        title: "批量注册"
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(FormInputOptions, item => {
            return _createVNode(_component_van_field, {
              key: item.field,
              label: item.name,
              modelValue: postData.value[item.field],
              "onUpdate:modelValue": $event => postData.value[item.field] = $event
            }, null, 8, ["label", "modelValue", "onUpdate:modelValue"]);
          }), 64))]),
          _: 1
        }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 提交 ")]),
          _: 1
        })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "warning",
          onClick: _cache[0] || (_cache[0] = () => _unref(Common_Router).RefreshPage())
        }, {
          default: _withCtx(() => [_createTextVNode(" 刷新 ")]),
          _: 1
        })])]),
        _: 1
      }), _createVNode(_component_van_tag, {
        style: {
          "margin": "0px 10px 10px 16px"
        }
      }, {
        default: _withCtx(() => [_createTextVNode("账号范围:" + _toDisplayString(_unref(CalcUserNameExample)), 1)]),
        _: 1
      })])], 64);
    };
  }
};